$(document).ready(function(){
	init(); 
	$(window).trigger('resize');
	$(window).trigger('scroll');
});

function init() {
	//Lazyload Images
	$(window).on('load scroll resize', function() {
		$('.u__object-fit:visible').each(function() {
			if ( isVisible($(this)) ) {
				if ( $(this).data('srcset') ) {
					$(this).attr('srcset', $(this).data('srcset'));
					$(this).removeAttr('data-srcset');
				}

				if ( $(this).data('src') ) {
					$(this).attr('src', $(this).data('src'));
					$(this).removeAttr('data-src');
				}			
			}
		});	
	});

	function isVisible(elem) {
		var scrollTop = $(window).scrollTop();
		var windowBottom = scrollTop + $(window).height();
		var offset = 100;
		var elementTop = elem.offset().top;

		if ( (windowBottom + offset) > elementTop ) {
			return true;
		} else {
			return false;
		}
	}	

	$('.js__hamburger-trigger').on('click', function(e) {
		e.preventDefault();

		$(this).toggleClass('active');
		$('.m__header').toggleClass('nav-open');
	});

	$(window).on('scroll', function() {
		var headerHeight = $('.m__header').height() + 1;

		if ( $(window).scrollTop() > headerHeight ) {
			$('.m__header').css({'transform' : 'translateY(-' + headerHeight +'px)'});
		} else {
			$('.m__header').removeAttr('style'); 
		}

		if ( $(window).scrollTop() > 150 ) {
			$('.m__header').addClass('sticky');
		} else {
			$('.m__header').removeClass('sticky');
		}		

		if ( $(window).scrollTop() > 275 ) {
			$('.m__header').addClass('slide-down');
		} else {
			$('.m__header').removeClass('slide-down');
		}
	});

	$('.m__header .nav-col .m__header-nav ul .menu-item.menu-item-has-children>a').on('click', function(e) {
		if ( $(window).width() < 1200 ) {
			if ( !$(this).hasClass('clicked-once') ) {
				e.preventDefault();

				$(this).siblings('.sub-menu').slideDown();

				$(this).addClass('clicked-once');
			}
		}
	});

	if ( $('.js__restaurants-slider').length ) {
		var arrow = '/wp-content/themes/cafemurano/library/images/icons/next-arrow.svg';

		$restaurant_slider = $('.js__restaurants-slider');
		settings = {
			slidesToShow: 4,
			slidesToScroll: 1,
			dots: false,
			arrows: true,
			swipe: true,
			nextArrow: "<div class='slick-arrow slick-next' style='background-image: url("+ arrow +");'></div>",
			prevArrow: "<div class='slick-arrow slick-prev' style='background-image: url("+ arrow +");'></div>",
			responsive: [{
				breakpoint: 992,
				settings: {
					slidesToShow: 3
				}
			}]			
		};
		$restaurant_slider.slick(settings);

		// reslick only if it's not slick()
		$(window).on('resize', function() {
			if ( $(window).width() < 768 ) {
				if ($restaurant_slider.hasClass('slick-initialized')) {
					$restaurant_slider.slick('unslick');
				}
				return;
			}

			if ( $(window).width() > 768 ) {
				if (!$restaurant_slider.hasClass('slick-initialized')) {
					return $restaurant_slider.slick(settings);
				}
			}
		});		
	}

	//Popups
	$('.popup').addClass('loaded');
	$(document).on('click', '.js__popup-trigger', function(e) {
		e.preventDefault();

		$('.popup .btn-container').trigger('click');

		var popup = $(this).data('popup');

		$('.popup .popup__item').removeClass('showing');
		$('.popup .popup__header .js__popup-close').show();
		$('html, body').css('overflow', 'hidden');

		$('.popup').addClass('open');
		$('.popup .popup__item#'+popup).addClass('open showing');
	});	

	// Custom Popup
	function getCookie(cname) {
		var name = cname + "=";
		var decodedCookie = decodeURIComponent(document.cookie);
		var ca = decodedCookie.split(';');

		for (var i = 0; i <ca.length; i++) {
			var c = ca[i];

			while (c.charAt(0) == ' ') {
		  		c = c.substring(1);
			}

			if (c.indexOf(name) == 0) {
		  		return c.substring(name.length, c.length);
			}
		}
		return false;
	}

	var popupCookie = getCookie('hide_closure_popup');

	if ( $('.popup').data('custom-popup') == 'show' && popupCookie != 'yes' ) {
		setTimeout(function() {
			$('.popup .btn-container').trigger('click');

			var popup = 'custom';

			$('.popup .popup__item').removeClass('showing');
			$('.popup .popup__header .js__popup-close').show();
			$('html, body').css('overflow', 'hidden');

			$('.popup').addClass('open');
			$('.popup .popup__item#'+popup).addClass('open showing');
		}, 2500);
	}

	$('.js__popup-close').on('click', function(e) {
		e.preventDefault();

		if ( $(this).data('cookie') == 'set' ) {
			setCookie('hide_closure_popup', 'yes');
		}

		$('.popup').removeClass('open');	
		$('.popup .popup__item').removeClass('open');
		$('html, body').removeAttr('style');
	});

	function setCookie(cname, cvalue) {
	    var date = new Date();
	    var expires = 'expires=';
	    date.setDate(date.getDate() + 1);
	    expires += date.toGMTString();
	    document.cookie = cname + '=' + cvalue + '; ' + expires + '; path=/';
	}	

	//Selectric
	$('.js__selectric').selectric();

	//Set booking times based on day of week/location
	var fourToEightThirtyPM = '<option value="16:00">4:00 PM</option><option value="16:30">4:30 PM</option><option value="17:00">5:00 PM</option><option value="17:30">5:30 PM</option><option value="18:00">6:00 PM</option><option value="18:30">6:30 PM</option><option value="19:00">7:00 PM</option><option value="19:30">7:30 PM</option><option value="20:00">8:00 PM</option><option value="20:30">8:30 PM</option>';
	var fourToEightPM = '<option value="16:00">4:00 PM</option><option value="16:30">4:30 PM</option><option value="17:00">5:00 PM</option><option value="17:30">5:30 PM</option><option value="18:00">6:00 PM</option><option value="18:30">6:30 PM</option><option value="19:00">7:00 PM</option><option value="19:30">7:30 PM</option><option value="20:00">8:00 PM</option>';
	var twelveToSevenThirtyPM = '<option value="12:00">12:00 PM</option><option value="12:30">12:30 PM</option><option value="13:00">1:00 PM</option><option value="13:30">1:30 PM</option><option value="14:00">2:00 PM</option><option value="14:30">2:30 PM</option><option value="15:00">3:00 PM</option><option value="15:30">3:30 PM</option><option value="16:00">4:00 PM</option><option value="16:30">4:30 PM</option><option value="17:00">5:00 PM</option><option value="17:30">5:30 PM</option><option value="18:00">6:00 PM</option><option value="18:30">6:30 PM</option><option value="19:00">7:00 PM</option><option value="19:30">7:30 PM</option>';
	var fourToTenPM = '<option value="16:00">4:00 PM</option><option value="16:30">4:30 PM</option><option value="17:00">5:00 PM</option><option value="17:30">5:30 PM</option><option value="18:00">6:00 PM</option><option value="18:30">6:30 PM</option><option value="19:00">7:00 PM</option><option value="19:30">7:30 PM</option><option value="20:00">8:00 PM</option><option value="20:30">8:30 PM</option><option value="21:00">9:00 PM</option><option value="21:30">9:30 PM</option><option value="22:00">10:00 PM</option>';
	var twelveToTenPM = '<option value="12:00">12:00 PM</option><option value="12:30">12:30 PM</option><option value="13:00">1:00 PM</option><option value="13:30">1:30 PM</option><option value="14:00">2:00 PM</option><option value="14:30">2:30 PM</option><option value="15:00">3:00 PM</option><option value="15:30">3:30 PM</option><option value="16:00">4:00 PM</option><option value="16:30">4:30 PM</option><option value="17:00">5:00 PM</option><option value="17:30">5:30 PM</option><option value="18:00">6:00 PM</option><option value="18:30">6:30 PM</option><option value="19:00">7:00 PM</option><option value="19:30">7:30 PM</option><option value="20:00">8:00 PM</option><option value="20:30">8:30 PM</option><option value="21:00">9:00 PM</option><option value="21:30">9:30 PM</option><option value="22:00">10:00 PM</option>';
	var twoToEightPM = '<option value="14:00">2:00 PM</option><option value="14:30">2:30 PM</option><option value="15:00">3:00 PM</option><option value="15:30">3:30 PM</option><option value="16:00">4:00 PM</option><option value="16:30">4:30 PM</option><option value="17:00">5:00 PM</option><option value="17:30">5:30 PM</option><option value="18:00">6:00 PM</option><option value="18:30">6:30 PM</option><option value="19:00">7:00 PM</option><option value="19:30">7:30 PM</option><option value="20:00">8:00 PM</option>';
	var twelveToEightPM = '<option value="12:00">12:00 PM</option><option value="12:30">12:30 PM</option><option value="13:00">1:00 PM</option><option value="13:30">1:30 PM</option><option value="14:00">2:00 PM</option><option value="14:30">2:30 PM</option><option value="15:00">3:00 PM</option><option value="15:30">3:30 PM</option><option value="16:00">4:00 PM</option><option value="16:30">4:30 PM</option><option value="17:00">5:00 PM</option><option value="17:30">5:30 PM</option><option value="18:00">6:00 PM</option><option value="18:30">6:30 PM</option><option value="19:00">7:00 PM</option><option value="19:30">7:30 PM</option><option value="20:00">8:00 PM</option>';
  	var availableBookingTimes = fourToEightThirtyPM;

	var currentDateForBooking = new Date();
	// If on thanksgiving page
	if ($('body').hasClass('postid-650')) { 
		currentDateForBooking = new Date(2020,11,26);
	}

	var currentDayOfWeek = currentDateForBooking.getDay(); //Sunday is 0, Monday is 1
	var thanksgivingDate = new Date(2020,10,26); //Thanks giving as months are base 0 (so this is actually 2020/11/26)
	var mothersdayDate = new Date(2022,4,8); //Thanks giving as months are base 0 (so this is actually 2020/11/26)
	var fathersdayDate = new Date(2022,5,19); //Thanks giving as months are base 0 (so this is actually 2020/11/26)
	//thanksgivingDate.setHours(0,0,0,0);

	changeAvailableBookingTimes();

	var currentSelectedLocation = $('#location').find(':selected').val();
	$('#location').on('change', function() {
    	currentSelectedLocation = $('#location').find(':selected').val();
    	changeAvailableBookingTimes();
  	});

	function changeAvailableBookingTimes( timeoverride = "" ) {
		availableBookingTimes = fourToEightThirtyPM;

		if ( (currentSelectedLocation == '1070215' || currentSelectedLocation == '1070209') && (currentDayOfWeek == 5 || currentDayOfWeek == 6) ) {
			availableBookingTimes = fourToEightPM; // If location is Seaport or Back Bay, and day of week is Fri or Sat.
		}
		if (currentDateForBooking.getTime() == thanksgivingDate.getTime()) {
			availableBookingTimes = twelveToSevenThirtyPM; // If it's thanksgiving
		}
		if (currentDateForBooking.getTime() == mothersdayDate.getTime()) {
			availableBookingTimes = twelveToEightPM; // If it's Mother's Day
		}
		if (currentDateForBooking.getTime() == fathersdayDate.getTime()) {
			availableBookingTimes = twoToEightPM; // If it's Father's Day
		}

		if (timeoverride != "") {
			availableBookingTimes = timeoverride;
		}

		$('#booking_time').selectric('destroy');
		$('#booking_time').find('option')
	    .remove()
	    .end()
	    .append(availableBookingTimes)
	    .selectric('refresh');
	}
 
	//No. of people select field
	$('#people').on('change', function() {
		var selectedPeopleNumber = $('#people').find(':selected').val();

		if(selectedPeopleNumber == "6+") {
			//Display message
			$('.popup__item-warning-message').addClass('is-active');
		} else {
			$('.popup__item-warning-message').removeClass('is-active');
		}
	});	

	//Pikaday
	function formatDate(number) {
		if ( number < 10 ) {
			return '0' + number;
		} else {
			return number;
		}
	}

	var x = 1; $('.js__pikaday-trigger').each(function() {
		$(this).closest('.popup__item-form-item').siblings('label').attr('for', 'date-trigger-'+x);
		$(this).attr('id', 'date-trigger-'+x);
		var bookingDate = new Pikaday({
		    field: document.getElementById('date-trigger-'+x),
		    format: 'MM/DD/YYYY',    
		    minDate: new Date(),
		    onSelect: function(date) {
				currentDateForBooking = date;

		        var day = date.getDate();
		        var month = formatDate(date.getMonth() + 1);
		        var year = formatDate(date.getFullYear());

				var arrivalDate = year + '-' + month + '-' + day;

				$('#bookingForm input#date').val(arrivalDate); 

				$('.popup__item-form-item select#booking_time').removeAttr('disabled');

				changeAvailableBookingTimes();	           	
			}
		});	
		x++;
	});	

	//Booking Form
	$('#bookingForm').on('submit', function(e) {
		e.preventDefault();

		// Clear previous error messages
		var errorCount = 0;
		$(this).find('.popup__item-form-label--error').hide();

		var restref = $(this).find('#location').find(':selected').val();
		var restaurant = $(this).find('#location').find(':selected').data('restaurant');
        var selectedDate = $(this).find('#date').val();
        var selectedTime = $(this).find('#booking_time').find(':selected').val();
        var time = selectedTime.replace(':', '%3A');
        var selectedPeople = $(this).find('#people').find(':selected').val();

        if ( restref == 0 ) {
        	$(this).find('.popup__item-form-label--error').show();
        	errorCount = 1;
        }

		var otURL = 'https://www.opentable.com/restaurant/profile/';
        otURL += restref + '/reserve/';
        otURL += '?covers=' + selectedPeople;
        otURL += '&datetime=' + selectedDate + 'T' + time;
        otURL += '&restref=' + restref;

        if ( errorCount === 0 ) {
        	window.open(otURL, '_blank');
        }
	});	

	$('#signupForm').on('submit', function(e) {
		e.preventDefault();

		const formData = $(this).serialize();

		$.ajax({
			type: 'POST',
			dataType: 'html',
			url: main__js_vars.ajaxurl,
			data: formData + '&action=ajax_newsletter_signup',
			beforeSend : function () {
				$('#signupForm').addClass('submitting');
				$('#signupForm input[type=submit]').text('Submitting');
			},
			success: function (data) {
				var returnedData = JSON.parse(data);
				console.log(returnedData);

				if ( returnedData.status != 'success' ) {
					$('.signup__success-message').text('An error has occured. Please try again.');
				}

				$('#signupForm').fadeOut(400);

				setTimeout(function() {
					$('.signup__success-message').fadeIn();
				}, 500);
			},
			error : function (jqXHR, textStatus, errorThrown) {
				$(this).html($.parseJSON(jqXHR.responseText) + ' :: ' + textStatus + ' :: ' + errorThrown);
				console.log(jqXHR);
			},
		}); 
	});

	//Order Online Form
	$('#orderOnline').on('submit', function(e) {
		e.preventDefault();

		var orderURL = $(this).find('#order-online-location').find(':selected').val();
		window.open(orderURL, '_blank');
	})

	//Hero Section - Module
	if ( $('.module-1 .hero__promo').length ) {
		$(window).on('resize', function() {
			var windowHeight = $(window).height();
			var promoHeight = $('.module-1 .hero__promo').outerHeight();
			var heroSliderHeight = windowHeight - promoHeight;
			
			$('.module-1 .hero__slider-container').css('height', heroSliderHeight+'px');
		});
	}

	if ( $('.js__hero-slider').length ) {
		var i = 1; $('.js__hero-slider').each(function() {
			$(this).addClass('js__hero-slider-'+i);

			$('.js__hero-slider-'+i).slick({
				autoplay: false,
				autoplaySpeed: 7000,
				speed: 1000,
				arrows: false,
				dots: false,
				fade: true
			});

			if ( $('body').hasClass('home') ) {
				$('.js__hero-slider-'+i+' .hero__slide').removeClass('hero__slide--hidden');
				$('.js__hero-slider-'+i).slick('slickSetOption', 'autoplay', true, true);
				$('.js__hero-slider-'+i).slick('slickSetOption', 'autoplaySpeed', 4000, true);
			}			

			i++;
		});
	}

	// Full Screen Text - Module
	/*if ( $('.full-screen-text__section.module-1').length ) {
		if ( $(window).width() > 991 ) {
			var lines = $('.full-screen-text__section.module-1 .text__content p').length;

			var i = 1; $('.full-screen-text__section.module-1 .text__content p').each(function() {
				var content = $(this).html();

				$(this).html('<span class="inner">'+content+'</span>');

				if ( i === lines ) {
					addLoadedClass(lines);
				}

				i++;
			});
		}
	}

	function addLoadedClass(lines) {
		setTimeout(function(){
			$('.full-screen-text__section.module-1 .text__content').addClass('loaded');

			if ( $('.full-screen-text__section.module-1 .text__small-text').length ) {
				var transitionDelay = (lines * 0.1) + 0.6;
				$('.full-screen-text__section.module-1 .text__small-text .inner').css('transition-delay', transitionDelay+'s');
				$('.full-screen-text__section.module-1 .text__small-text').addClass('loaded');
			}
		}, 400);
	}*/

	// CTA Block 2 Columns - Module
	if ( $('.cta__block-2-cols').length ) {
		var i = 1; $('.cta__block-2-cols').each(function() {
			$(this).addClass('cta__block-2-cols-'+i);

			$('.cta__block-2-cols-'+i+' .cta__title').matchHeight();
			$('.cta__block-2-cols-'+i+' .cta__description').matchHeight();

			i++;
		});
	}	

	// Content and Image Block - Module
	if ( $('.js__ci-block-image-slider').length ) {
		var nextArrow = '/wp-content/themes/strega/library/images/icons/chevron-right.svg';
		var prevArrow = '/wp-content/themes/strega/library/images/icons/chevron-left.svg';

		var i = 1; $('.js__ci-block-image-slider').each(function() {
			$(this).addClass('js__ci-block-image-slider-'+i);	


			$('.js__ci-block-image-slider-'+i).on('init', function(event, slick){
				$('.js__ci-block-image-slider-'+i+' .ci-block__image').each(function () {
				    var $slide = $(this).parent();    
				    if ($slide.attr('aria-describedby') != undefined) { // ignore extra/cloned slides
				        $(this).attr('id', $slide.attr('aria-describedby'));
				    }
				});

				$('.js__ci-block-image-slider-'+i+' .slick-track').removeAttr('role');
			});						

			$('.js__ci-block-image-slider-'+i).slick({
				autoplay: false,
				arrows: true,
				nextArrow: "<div class='slick-arrow slick-next' style='background-image: url("+ nextArrow +");'></div>",
				prevArrow: "<div class='slick-arrow slick-prev' style='background-image: url("+ prevArrow +");'></div>",				
				dots: false,
				fade: true
			});

			i++;
		});
	}	

	// Menu Links - Module
	if ( $('.js__menu__link-slider').length ) {
		var i = 1; $('.js__menu__link-slider').each(function() {
			$(this).addClass('js__menu__link-slider-'+i);

			$('.js__menu__link-slider-'+i).slick({
				autoplay: false,
				arrows: false,
				dots: false,
				fade: true,
				draggable: false,
				swipe: false,
				speed: 750
			});

			$('.js__menu__link-slider-nav').on('mouseover', function() {
				var slideID = $(this).data('slide-id');
				var slider = $(this).parentsUntil('.menu__links').find('.js__menu__link-slider');

				slider.slick('slickGoTo', slideID, false);
			});

			i++;
		});
	}	

	// Content Block - Module
	if ( $('.js__content-block-slider').length ) {
		var nextArrow = '/wp-content/themes/strega/library/images/icons/chevron-right.svg';
		var prevArrow = '/wp-content/themes/strega/library/images/icons/chevron-left.svg';

		var i = 1; $('.js__content-block-slider').each(function() {
			$(this).addClass('js__content-block-slider-'+i);
			
			var slidesToShow = $(this).data('slides-to-show');

			$('.js__content-block-slider-'+i).slick({
				autoplay: false,
				slidesToShow: slidesToShow,
				arrows: true,
				dots: false,
				speed: 750,
				nextArrow: "<div class='slick-arrow slick-next' style='background-image: url("+ nextArrow +");'></div>",
				prevArrow: "<div class='slick-arrow slick-prev' style='background-image: url("+ prevArrow +");'></div>"				
			});

			i++;
		});
	}	

	// Menus Inline - Module
	if ( $('.js__menu-locations-slider').length ) {
		var initialSlide = $('.js__menu-locations-slider .menu__location-div .menu__location.active').data('index') - 1;

		$menu_location_slider = $('.js__menu-locations-slider');
		settings = {
			initialSlide: initialSlide,
			slidesToShow: 4,
			dots: false,
			arrows: false,
			swipe: true,		
			centerMode: false,
			draggable: true,		
			responsive: [{
				breakpoint: 991, 
				settings: {
					slidesToShow: 4,
				},
				breakpoint: 767,
				settings: {
					slidesToShow: 3,
				},
				breakpoint: 575,
				settings: {
					slidesToShow: 1,
				}			
			}]			
		};		
		$menu_location_slider.slick(settings);

		$menu_location_slider.on('beforeChange', function(event, slick, currentSlide, nextSlide){
			var newLocation = $('.menu__location-div[data-slick-index="'+nextSlide+'"] .js__menu-location-trigger');

			$('.menu__location-div .menu__location').removeClass('active');
	  		newLocation.addClass('active');
	  		
	  		var restaurant = newLocation.data('menu-location');
	  		ajaxMenus(restaurant, '', 'no');
		});

		// reslick only if it's not slick()
		$(window).on('resize', function() {
			if ( $(window).width() > 991 ) {
				if ($menu_location_slider.hasClass('slick-initialized')) {
					$menu_location_slider.slick('unslick');
				}
				return;
			}

			if ( $(window).width() < 991 ) {
				if (!$menu_location_slider.hasClass('slick-initialized')) {
					return $menu_location_slider.slick(settings);
				}
			}
		});		
	}		
	
	$('.js__menu-location-trigger').on('click', function(e) {
		e.preventDefault();
		if ( !$(this).hasClass('active') ) {
			var restaurant = $(this).data('menu-location');

			$('.js__menu-location-trigger').removeClass('active');
			$('.js__menu-location-trigger[data-menu-location='+restaurant+']').addClass('active');

           	ajaxMenus(restaurant, '', 'no');
		}
	});

	$(document).on('click', '.js__change-menu', function(e) {
		e.preventDefault();

		if ( !$(this).hasClass('active') ) {
			var menuID = $(this).data('menu-id');
			var restaurant = $('.js__menu-location-trigger.active').data('menu-location');

			$('.js__change-menu').removeClass('active');
			$(this).addClass('active');

	       	ajaxMenus(restaurant, menuID, 'yes');
		}
	});	

	$(document).on('change', '.menu__list-dropdown', function(e) {
		var restaurant = $('.js__menu-location-trigger.active').data('menu-location');
		var menuID = $(this).val();

		ajaxMenus(restaurant, menuID, 'yes');
	});

	function ajaxMenus(restaurant, menuID, sameLocation) {
		var content = $('#menus_ajax_container');

	    // Set min height for the content
	    var minHeight = content.outerHeight();
	    content.css('min-height', minHeight+'px');	

	    $.ajax({
	        type: 'POST',
	        dataType: 'html',
	        url: main__js_vars.ajaxurl,
	        data: {
	            'restaurant': restaurant,
	            'action': 'ajax_menu_location'
	        },
	        beforeSend : function () {
	            content.addClass('fading-out');
	        },
	        success: function (data) {
	            var returnedData = JSON.parse(data);

	            if ($(returnedData.menus).length) {
	                $('#menus_ajax_container .menu').remove();
	                $.when($('#menus_ajax_container .menu').remove()).then(content.append($(returnedData.menus)));
				}

	            content.removeAttr('style');                    
	            content.removeClass('fading-out');
	        },
	        error : function (jqXHR, textStatus, errorThrown) {
	            $(this).html($.parseJSON(jqXHR.responseText) + ' :: ' + textStatus + ' :: ' + errorThrown);
	            console.log(jqXHR);
	        },
	    }); 
	}

	// Private Dining - Module
	if ( $('#private-dining-section .js__pe-locations-slider').length ) {
		var initialSlide = $('#private-dining-section .js__pe-locations-slider .menu__location-div .menu__location.active').data('index') - 1;

		$menu_location_slider = $('#private-dining-section .js__pe-locations-slider');
		settings = {
			initialSlide: initialSlide,
			slidesToShow: 4,
			dots: false,
			arrows: false,
			swipe: true,		
			centerMode: false,
			draggable: true,		
			responsive: [{
				breakpoint: 991, 
				settings: {
					slidesToShow: 4,
				},
				breakpoint: 767,
				settings: {
					slidesToShow: 3,
				},
				breakpoint: 575,
				settings: {
					slidesToShow: 1,
				}			
			}]			
		};		
		$menu_location_slider.slick(settings);

		$menu_location_slider.on('beforeChange', function(event, slick, currentSlide, nextSlide){
			var newLocation = $('.menu__location-div[data-slick-index="'+nextSlide+'"] .js__pe-location-trigger');

			$('.menu__location-div .menu__location').removeClass('active');
	  		newLocation.addClass('active');
	  		
	  		var restaurant = newLocation.data('pe-location');
	  		ajaxPrivateDining(restaurant, '', 'no');
		});

		// reslick only if it's not slick()
		$(window).on('resize', function() {
			if ( $(window).width() > 991 ) {
				if ($menu_location_slider.hasClass('slick-initialized')) {
					$menu_location_slider.slick('unslick');
				}
				return;
			}

			if ( $(window).width() < 991 ) {
				if (!$menu_location_slider.hasClass('slick-initialized')) {
					return $menu_location_slider.slick(settings);
				}
			}
		});		
	}		
	
	$('#private-dining-section .js__pe-location-trigger').on('click', function(e) {
		e.preventDefault();

		if ( !$(this).hasClass('active') ) {
			var restaurant = $(this).data('pe-location');

			$('.js__pe-location-trigger').removeClass('active');
			$('.js__pe-location-trigger[data-pe-location='+restaurant+']').addClass('active');

           	ajaxPrivateDining(restaurant, '', 'no');
		}
	});

	if ( $('#strega-caffe-section .js__pe-locations-slider').length ) {
		var initialSlide = $('#strega-caffe-section .js__pe-locations-slider .menu__location-div .menu__location.active').data('index') - 1;

		$menu_location_slider = $('#strega-caffe-section .js__pe-locations-slider');
		settings = {
			initialSlide: initialSlide,
			slidesToShow: 4,
			dots: false,
			arrows: false,
			swipe: true,
			centerMode: false,
			draggable: true,
			responsive: [{
				breakpoint: 991,
				settings: {
					slidesToShow: 4,
				},
				breakpoint: 767,
				settings: {
					slidesToShow: 3,
				},
				breakpoint: 575,
				settings: {
					slidesToShow: 1,
				}
			}]
		};
		$menu_location_slider.slick(settings);

		$menu_location_slider.on('beforeChange', function(event, slick, currentSlide, nextSlide){
			var newLocation = $('.menu__location-div[data-slick-index="'+nextSlide+'"] .js__pe-location-trigger');

			$('.menu__location-div .menu__location').removeClass('active');
			newLocation.addClass('active');

			var restaurant = newLocation.data('pe-location');
			ajaxLocationDetails(restaurant, 'About', 'yes');
		});

		// reslick only if it's not slick()
		$(window).on('resize', function() {
			if ( $(window).width() > 991 ) {
				if ($menu_location_slider.hasClass('slick-initialized')) {
					$menu_location_slider.slick('unslick');
				}
				return;
			}

			if ( $(window).width() < 991 ) {
				if (!$menu_location_slider.hasClass('slick-initialized')) {
					return $menu_location_slider.slick(settings);
				}
			}
		});
	}

	$('#strega-caffe-section .js__pe-location-trigger').on('click', function(e) {
		e.preventDefault();
		if ( !$(this).hasClass('active') ) {
			var restaurant = $(this).data('pe-location');

			$('.js__pe-location-trigger').removeClass('active');
			$('.js__pe-location-trigger[data-pe-location='+restaurant+']').addClass('active');
			$('.js__change-pe-room').removeClass('active');
			$('.js__change-pe-room[data-type=about]').addClass('active');

			ajaxLocationDetails(restaurant, 'About', 'yes');
		}
	});

	$(document).on('click', '#private-dining-section .js__change-pe-room', function(e) {
		e.preventDefault();

		if ( !$(this).hasClass('active') ) {
			var room = $(this).data('pe-id');
			var restaurant = $('.js__pe-location-trigger.active').data('pe-location');

			$('.js__change-pe-room').removeClass('active');
			$(this).addClass('active');

	       	ajaxPrivateDining(restaurant, room, 'yes');
		}
	});	

	$(document).on('change', '#private-dining-section .pe__list-dropdown', function(e) {
		var restaurant = $('.js__pe-location-trigger.active').data('pe-location');
		var room = $(this).val();

		ajaxPrivateDining(restaurant, room, 'yes');
	});

	function ajaxPrivateDining(restaurant, room, sameLocation) {
		var content = $('#ajax_pd_container');

	    // Set min height for the content
	    var minHeight = content.outerHeight();
	    content.css('min-height', minHeight+'px');	

	    var introContent = $('#ajax_pd_intro_container');
	    var introMinHeight = introContent.outerHeight();
	    introContent.css('min-height', introMinHeight+'px');	    

	    $.ajax({
	        type: 'POST',
	        dataType: 'html',
	        url: main__js_vars.ajaxurl,
	        data: {
	            'restaurant': restaurant,
	            'room': room,
	            'action': 'ajax_private_dining'
	        },
	        beforeSend : function () {
	            content.addClass('fading-out');

	            if ( sameLocation == 'no' ) {
	            	$('.menus__list-container').addClass('fading-out');
	            	introContent.addClass('fading-out');
	            }
	        },
	        success: function (data) {
	            var returnedData = JSON.parse(data);

	            if ($(returnedData.room).length) {
	            	if ( sameLocation == 'no' ) {
		                $('.menus__list-container .menu__list').remove();
		                $('.menus__list-container').append($(returnedData.rooms_list));
		                introContent.html('');
		                introContent.html($(returnedData.intro_text))
	            	}
	                $('#ajax_pd_container .menu').remove();
	                $.when($('#ajax_pd_container .menu').remove()).then(content.append($(returnedData.room)));
	            }
	            
				var nextArrow = '/wp-content/themes/strega/library/images/icons/chevron-right.svg';
				var prevArrow = '/wp-content/themes/strega/library/images/icons/chevron-left.svg';

				$('.js__content-block-slider').slick({
					autoplay: false,
					slidesToShow: 1,
					arrows: true,
					dots: false,
					speed: 750,
					nextArrow: "<div class='slick-arrow slick-next' style='background-image: url("+ nextArrow +");'></div>",
					prevArrow: "<div class='slick-arrow slick-prev' style='background-image: url("+ prevArrow +");'></div>"				
				});	            

	            content.removeAttr('style');                    
	            content.removeClass('fading-out');

	            if ( sameLocation == 'no' ) {
	            	$('.menus__list-container').removeClass('fading-out');
					introContent.removeAttr('style');                    
					introContent.removeClass('fading-out');	            	
	            }
	        },
	        error : function (jqXHR, textStatus, errorThrown) {
	            $(this).html($.parseJSON(jqXHR.responseText) + ' :: ' + textStatus + ' :: ' + errorThrown);
	            console.log(jqXHR);
	        },
	    }); 
	}

	$(document).on('click', '#strega-caffe-section .js__change-pe-room', function(e) {
		e.preventDefault();

		if ( !$(this).hasClass('active') ) {
			var type = $(this).data('type');
			var restaurant = $('.js__pe-location-trigger.active').data('pe-location');

			$('.js__change-pe-room').removeClass('active');
			$(this).addClass('active');

			ajaxLocationDetails(restaurant, type, 'yes');
		}
	});

	$(document).on('change', '#strega-caffe-section .pe__list-dropdown', function(e) {
		var restaurant = $('.js__pe-location-trigger.active').data('pe-location');
		var type = $(this).find(':selected').attr('data-type');
		ajaxLocationDetails(restaurant, type, 'yes');
	});

	function ajaxLocationDetails(restaurant, type, sameLocation) {
		var content = $('#ajax_pd_container');

		// Set min height for the content
		var minHeight = content.outerHeight();
		content.css('min-height', minHeight+'px');

		var introContent = $('#ajax_pd_intro_container');
		var introMinHeight = introContent.outerHeight();
		introContent.css('min-height', introMinHeight+'px');

		$.ajax({
			type: 'POST',
			dataType: 'html',
			url: main__js_vars.ajaxurl,
			data: {
				'restaurant': restaurant,
				'type': type,
				'action': 'ajax_location_details'
			},
			beforeSend : function () {
				content.addClass('fading-out');
			},
			success: function (data) {

				var returnedData = JSON.parse(data);

				if ($(returnedData.details).length) {
					$('#ajax_pd_container .menu').remove();
					$.when($('#ajax_pd_container .menu').remove()).then(content.append($(returnedData.details)));
				}

				var nextArrow = '/wp-content/themes/strega/library/images/icons/chevron-right.svg';
				var prevArrow = '/wp-content/themes/strega/library/images/icons/chevron-left.svg';

				$('.js__content-block-slider').slick({
					autoplay: false,
					slidesToShow: 1,
					arrows: true,
					dots: false,
					speed: 750,
					nextArrow: "<div class='slick-arrow slick-next' style='background-image: url("+ nextArrow +");'></div>",
					prevArrow: "<div class='slick-arrow slick-prev' style='background-image: url("+ prevArrow +");'></div>"
				});

				content.removeAttr('style');
				content.removeClass('fading-out');
			},
			error : function (jqXHR, textStatus, errorThrown) {
				$(this).html($.parseJSON(jqXHR.responseText) + ' :: ' + textStatus + ' :: ' + errorThrown);
				console.log(jqXHR);
			},
		});
	}

	/*$('.js__change-restaurant').on('click', function(e) {
		e.preventDefault();

		if ( !$(this).hasClass('active') ) {
			var restaurantID = $(this).data('restaurant-id');

			$('.js__change-restaurant').removeClass('active');
			$(this).addClass('active');

			ajaxPrivateDining(restaurantID);
		}
	});

	function ajaxPrivateDining(restaurantID) {
		var content = $('#ajax_pd_container');

	    // Set min height for the content
	    var minHeight = content.outerHeight();
	    content.css('min-height', minHeight+'px');	

	    $.ajax({
	        type: 'POST',
	        dataType: 'html',
	        url: main__js_vars.ajaxurl,
	        data: {
	            'restaurantID': restaurantID,
	            'action': 'ajax_private_dining'
	        },
	        beforeSend : function () {
	            content.addClass('fading-out');
	        },
	        success: function (data) {
	            var returnedData = JSON.parse(data);

	            $('#ajax_pd_container .content__block-row').remove();
	            $.when($('#ajax_pd_container .content__block-row').remove()).then($('#ajax_pd_container').append($(returnedData.private_dining_info)));

				var nextArrow = '/wp-content/themes/cafemurano/library/images/icons/next-arrow-gold-bg.svg';		
				var prevArrow = '/wp-content/themes/cafemurano/library/images/icons/prev-arrow-gold-bg.svg';

				$('.js__content-block-slider').slick({
					autoplay: false,
					slidesToShow: 1,
					arrows: true,
					dots: false,
					speed: 750,
					nextArrow: "<div class='slick-arrow slick-next' style='background-image: url("+ nextArrow +");'></div>",
					prevArrow: "<div class='slick-arrow slick-prev' style='background-image: url("+ prevArrow +");'></div>"				
				});

	            content.removeAttr('style');                    
	            content.removeClass('fading-out');
	        },
	        error : function (jqXHR, textStatus, errorThrown) {
	            $(this).html($.parseJSON(jqXHR.responseText) + ' :: ' + textStatus + ' :: ' + errorThrown);
	            console.log(jqXHR);
	        },
	    }); 
	}*/

	// News events - Module
	$('.js__news-events-category').on('click', function(e) {
		if ( $(this).hasClass('active') ) {
			e.preventDefault();
		}
	});	

	// Restaurants Nav - Module
	if ( $('.restaurants__nav').length ) {
		var headerHeight = $('.m__header').outerHeight();
		var restaurantNavTop = $('.restaurants__nav').offset().top;

		$(window).on('scroll', function(e) {
			if ( $(window).scrollTop() >= restaurantNavTop - headerHeight ) {
				$('.restaurants__nav').css({'position': 'fixed', 'top': headerHeight, 'z-index': 3, 'width': '100%'});
			} else {
				$('.restaurants__nav').removeAttr('style');
			}
		});
	}

	$('.js__restaurant-nav-link').on('click', function(e) {
		e.preventDefault();

		var scrollSection = $(this).data('scroll-section');
		var restaurantNavHeight = $('.restaurants__nav').outerHeight();

	    var offset = headerHeight + restaurantNavHeight;

	    $('html, body').animate({
	        scrollTop: $('.'+scrollSection).offset().top - offset
	    }, 800);
	});	

	$('#contact_form').on('submit', function(e) {
		e.preventDefault();

		const formData = $(this).serialize();
		ajaxSubmitForm(formData, '#contact_form');
	});

	$('#donations_form').on('submit', function(e) {
		e.preventDefault();

		const formData = $(this).serialize();
		ajaxSubmitForm(formData, '#donations_form');
	});

	function ajaxSubmitForm(formData, formID) {
		const formType = formID === '#contact_form' ? 'contact' : 'donations';

	    $.ajax({
	        type: 'POST',
	        dataType: 'html',
	        url: main__js_vars.ajaxurl,
	        data: formData + '&form_type='+formType+'&action=ajax_submit_form',
	        beforeSend : function () {
				$(formID).closest('.contact-form__form').addClass('submitting');
				$(formID).find('#cf__submit-btn .c__btn-text').text('Submitting...');
	        },
	        success: function (data) {
	            var returnedData = JSON.parse(data);

	            window.location.href = returnedData.redirect_url;
	        },
	        error : function (jqXHR, textStatus, errorThrown) {
	            $(this).html($.parseJSON(jqXHR.responseText) + ' :: ' + textStatus + ' :: ' + errorThrown);
	            console.log(jqXHR);
	        },
	    });			
	}
}